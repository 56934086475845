import '../styles/Contact.css'

function Contact(){

    return(
        <div id='contact'>
            <p>
            muriel.hediger@icloud.com<br/>
            +41 79 471 48 31<br/>
            Muriel Hediger<br/>
            Via S. Balestra 39<br/>
            6900 Lugano
            </p>
        </div>
    )

}

export default Contact